@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif:ital,wght@0,400;0,700;1,400&display=swap");

*,
html,
body {
  font-family: "Inter", sans-serif;
  /* font-family: "Noto Serif", serif; */
  scroll-behavior: smooth;
  /* background-color: var(--bg-color); */
  color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #646464;
  /* scrollbar-dark-shadow-color: #646464; */
}
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
::-webkit-scrollbar-button {
  background-color: #666;
}
::-webkit-scrollbar-track {
  background-color: #646464;
}
::-webkit-scrollbar-track-piece {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  /* border-radius: 3px; */
}
::-webkit-scrollbar-corner {
  background-color: #646464;
  /* background-color: var(--main-color); */
}
::-webkit-resizer {
  background-color: #666;
}

:root {
  --main-color: #ba8965;
  --secondary-color: #2d2d2d;
  --bg-color: #3f3f3f;
  --bg-secondary-color: #3b3b3b;
  --line-color: #323232;
}

a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}

ul,
li {
  list-style: none;
  background-color: inherit;
}

span {
  background-color: inherit;
}

/*-----------------------------------------------------*/
.blur {
  filter: blur(1px);
}

.allow-select {
  -webkit-user-select: text !important; /* Safari */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* IE 10 and IE 11 */
  user-select: text !important; /* Standard syntax */
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/*Custom Burger Icon*/
/* Icon 1 */

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 25px;
  height: 40px;
  position: relative;
  margin: auto 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--main-color);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 2 */

/* #nav-icon2 {
} */

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: var(--main-color);
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 10px;
  /* top: 8px; */
  /* top: 10px; */
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 26px;
  /* top: 28px; */
  /* top: 36px; */
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* Icon 4 */

/* #nav-icon4 {
} */

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

/*-----------------------------------------------------*/

/*Suspense fallback={<LoadingIndicator />}*/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--main-color);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*----------------------------------------------------------------------------------------------------*/
.ActiveSection {
  color: var(--main-color);
}
/*Form stuff---------------------------------------------------------------------------------------------*/
.chakra-form__label {
  font-size: 16px !important;
}
@media (max-width: 600px) {
  .chakra-form__label {
    font-size: 14px !important;
  }
}
.chakra-checkbox {
  margin-right: 10px;
}
/*----------------------------------------------------------------------------------------------------*/
input {
  color-scheme: dark;
}

#nav-icon3 {
  background-color: transparent !important;
}
.burger-icon.open {
  background-color: var(--bg-secondary-color) !important;
  /* background-color: transparent !important; */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 0.4; /* Firefox */
  font-weight: 300;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
  opacity: 0.4;
  font-weight: 300;
  font-size: 16px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff !important;
  opacity: 0.4;
  font-weight: 300;
  font-size: 16px;
}

@media (max-width: 1000px) {
  ::placeholder {
    font-size: 15px;
  }
  :-ms-input-placeholder {
    font-size: 15px;
  }
  ::-ms-input-placeholder {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  ::placeholder {
    font-size: 14px;
  }
  :-ms-input-placeholder {
    font-size: 14px;
  }
  ::-ms-input-placeholder {
    font-size: 14px;
  }
}

/* Chakra form styles... */
.chakra-form-control {
  background-color: transparent !important;
}

/* .chakra-input {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  background-color: var(--bg-secondary-color) !important;
} */ /*old way... used a different class to target..*/

.NewsletterFormInput {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  background-color: var(--bg-secondary-color) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #353535 inset !important;
}

.chakra-button {
  border: none !important;
  border-radius: 0px !important;
  background-color: var(--bg-secondary-color) !important;
  padding: 5px 20px !important;
  text-transform: uppercase !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}
@media (max-width: 1000px) {
  .chakra-button {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .chakra-button {
    font-size: 14px;
  }
}

.chakra-checkbox__control[data-checked] {
  border-color: var(--main-color) !important;
  background: none !important;
}
.chakra-checkbox__control[data-focus-visible] {
  box-shadow: var(--main-color) !important;
}

/*Chakra-checkbox-label*/
.chakra-checkbox__label {
  font-size: 12px !important;
}
@media (max-width: 1000px) {
  .chakra-checkbox__label {
    font-size: 11px !important;
  }
}
@media (max-width: 600px) {
  .chakra-checkbox__label {
    font-size: 10px !important;
  }
}

/*Toast*/
.chakra-form__error-message {
  margin-top: -20px !important;
  margin-bottom: 20px !important;
  background-color: var(--bg-secondary-color) !important;
}

#chakra-toast-manager-bottom,
.chakra-toast {
  background-color: unset !important;
}

/*Chakra accordion... FOOTER*/
.chakra-accordion__button {
  background-color: var(--bg-secondary-color) !important;
  /* border-top-width: none !important; */
  /* border-top: 1px solid #6c6c6c !important; */
}

/* .chakra-accordion__item {
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-top: 1px solid #6c6c6c;
  border-bottom: 1px solid #6c6c6c;
} */

.chakra-accordion__button:hover .chakra-accordion__icon path {
  fill: var(--main-color);
}

.chakra-accordion__panel > * + * {
  background-color: var(--bg-secondary-color) !important;
  margin-top: 8px;
}

.chakra-collapse .chakra-accordion__panel {
  background-color: var(--bg-secondary-color) !important;
}

.chakra-accordion__item {
  background-color: var(--bg-secondary-color) !important;
}

.chakra-icon,
.chakra-accordion__icon {
  background-color: var(--bg-secondary-color) !important;
}
/*-------------------------------------------------*/

/*-------HOME-PAGE-CAROUSEL-------*/
.carousel .thumbs {
  display: none;
}

.carousel .control-dots {
  background-color: transparent !important;
}

.carousel .carousel-status {
  display: none;
}

.carousel .thumbs-wrapper {
  display: none !important;
}

.carousel .control-dots {
  bottom: 40px !important;
}

/*-------NEWS-PAGE-CAROUSEL-------*/
.news-page-caro {
  width: 100% !important;
}
.news-page-caro .carousel .control-dots {
  bottom: 0px !important;
}
